/** @jsx jsx */
import { Grid, Box, jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderCard from "../components/header-card"
import ProductCard from "../components/product-card"
import TagsConstructor from "../components/tags"
import jamstackStyle from "../components/jamstack.styles"

const stylingStyle = {
  container: {
    backgroundColor: "coverBackground",
    pb: 4,
  },
  containerBox: {
    variant: "variants.container",
  },
  containerSection: {
    pb: 4,
  },
}

export const pageQuery = graphql`
  query stylingSingleQuery($id: String!) {
    strapiStyling(id: { eq: $id }) {
      products {
        id
        title
        slug
        prefix
        Status
        meta_description
        author
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        productType {
          price
        }
      }
      title
      info {
        meta_description
        title
      }
    }
  }
`

class StylingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showPosts: 12 }

    this.handleLoadMore = this.handleLoadMore.bind(this)
  }

  handleLoadMore() {
    this.setState(state => ({
      showPosts: state.showPosts + 9,
    }))
  }

  render() {
    const { strapiStyling } = this.props.data
    const { title, products, info } = strapiStyling
    const productCount = products.length
    const pageTitle = info && info.title ? info.title : title
    const pageDescription =
      info && info.meta_description ? info.meta_description : null

    const listProducts = products.map(product => {
      const {
        title,
        id,
        meta_description,
        slug,
        prefix,
        featuredImage,
        productType,
        author,
      } = product
      const path = prefix + slug
      const props = {
        id,
        slug,
        prefix,
        featuredImage,
        title,
        meta_description,
        productType,
        author,
        path,
      }

      return <ProductCard key={product.id} data={props} />
    })

    const showProducts = listProducts.slice(0, this.state.showPosts)

    return (
      <Layout>
        <SEO
          title={pageTitle + " • Stackrole"}
          description={pageDescription ? pageDescription : ""}
        />
        <div sx={jamstackStyle.navBg}>
          <div sx={jamstackStyle.navContainer}>
            <TagsConstructor {...jamstackStyle.navDropdown} />
          </div>
        </div>
        <div sx={stylingStyle.container}>
          <Box sx={stylingStyle.containerBox}>
            <section sx={stylingStyle.containerSection}>
              <HeaderCard>
                <h1>{title}</h1>
                {pageDescription && <p>{pageDescription}</p>}
              </HeaderCard>
              <Grid columns={[1, 2, 3]} sx={{ gridGap: 4 }}>
                {showProducts}
              </Grid>
              {this.state.showPosts < productCount && (
                <button
                  onClick={this.handleLoadMore}
                  className="button"
                  sx={{ variant: "variants.paginationButton" }}
                >
                  Load more
                </button>
              )}
            </section>
          </Box>
        </div>
      </Layout>
    )
  }
}

export default StylingPage
